import Header from "./components/Header"
import { ThemeProvider } from "styled-components"
import { HelmetProvider } from "react-helmet-async"
import { theme } from "./styles/themes"
import GlobalStyle from "./styles/styles"
import Hero from "./components/Hero"
import Callout from "./components/Callout"
import Menu from "./components/Menu"
import GiftCards from "./components/GiftCards"
import Reviews from "./components/Reviews"
import About from "./components/About"
import Footer from "./components/Footer"
import Follow from "./components/Follow"
import Application from "./components/Application"

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <HelmetProvider>
                <Header />
                <Hero />
                <Follow />
                <Callout />
                <Menu />
                <GiftCards />
                <About />
                <Reviews />
                <Application />
                <Footer />
            </HelmetProvider>
        </ThemeProvider>
    )
}

export default App
