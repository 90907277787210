import styled from "styled-components";
import { GlobalTitle } from "../styles/styles";
import menuTitle from "../static/assets/title-menu.webp";
import concrete from "../static/assets/concrete.webp";
import nutConcrete from "../static/assets/nut-concretes.webp";
import blobPink from "../static/assets/blob-pink.webp";
import blobBrown from "../static/assets/blob-brown.webp";
import blobCream from "../static/assets/blob-cream.webp";
import allFlavors from "../static/assets/all-flavors.webp";
import almonds from "../static/assets/almonds.webp";
import cashews from "../static/assets/cashews.webp";
import pecans from "../static/assets/pecans.webp";
import peanuts from "../static/assets/peanuts.webp";
import pint from "../static/assets/pint.webp";
import quart from "../static/assets/quart.webp";
import box from "../static/assets/box.webp";
import takeHome from "../static/assets/take-home-v2.webp";
import chocolate from "../static/assets/chocolate.webp";
import vanilla from "../static/assets/vanilla.webp";
import fotd from "../static/assets/fotd.webp";
import frozencustard from "../static/assets/frozencustard.webp";
import toppers from "../static/assets/toppers.webp";
import cones from "../static/assets/cones.webp";
import floats from "../static/assets/floats.webp";
import soda from "../static/assets/soda.webp";
import { motion } from "framer-motion";

const StyledContainer = styled.div`
  width: 100%;
  .container {
    max-width: 114rem;
    margin: 0 auto;
    padding: 3rem;
    .title {
      max-width: 20rem;
    }
    .product-flex {
      display: flex;
      gap: 3.5rem;
      &.reversed {
        flex-direction: row-reverse;
      }
      .image {
        flex: 1;
        position: relative;
        .ice-cream {
          max-width: 30rem;
          position: relative;
          z-index: 1;
        }
        .blob {
          z-index: 0;
          position: absolute;
          max-width: 40rem;
          top: 7rem;
          left: 7rem;
          &.brown {
            left: -10rem;
            top: 25rem;
          }
          &.cream {
            left: 2.5rem;
            top: 2.5rem;
          }
        }
      }
      .content {
        position: relative;
        z-index: 1;
        flex-grow: 1;
        p {
          max-width: 60rem;
        }
        h3 {
          font-size: 3.5rem;
          text-decoration: underline;
          color: ${(props) => props.theme.colors.primary};
        }
        .available {
          font-size: 1.4rem;
          text-transform: uppercase;
          font-weight: bold;
          padding-top: 3rem;
        }
        .flavors {
          gap: 3.5rem;
          display: flex;
          align-items: flex-start;
          a {
            text-decoration: none;
            color: #000;
          }
          & > div {
            padding: 2.5rem 0rem;
            .flavor {
              transition: all 0.25s ease-in-out;
              width: 7.5rem;
              height: 7.5rem;
              margin: 0 auto;
              display: block;
              &.chocolate {
                /* border-radius: 5rem;
                                background: #92530a; */
                background: url(${chocolate}) no-repeat center;
                background-size: contain;
              }
              &.vanilla {
                /* border-radius: 5rem;
                                background: #fbe8d2; */
                background: url(${vanilla}) no-repeat center;
                background-size: contain;
              }
              &.fotd {
                /* border-radius: 5rem;
                                background: #e72222; */
                background: url(${fotd}) no-repeat center;
                background-size: contain;
              }
              &.all-flavors {
                background: url(${allFlavors}) no-repeat center;
                background-size: contain;
              }
              &.almonds {
                background: url(${almonds}) no-repeat center;
                background-size: contain;
              }
              &.cashews {
                background: url(${cashews}) no-repeat center;
                background-size: contain;
              }
              &.pecans {
                background: url(${pecans}) no-repeat center;
                background-size: contain;
              }
              &.peanuts {
                background: url(${peanuts}) no-repeat center;
                background-size: contain;
              }
              &.pint {
                background: url(${pint}) no-repeat center;
                background-size: contain;
              }
              &.quart {
                background: url(${quart}) no-repeat center;
                background-size: contain;
              }
              &.box {
                background: url(${box}) no-repeat center;
                background-size: contain;
              }
            }
            .label {
              text-align: center;
              text-transform: uppercase;
              font-size: 1.2rem;
              padding-top: 1rem;
              max-width: 10rem;
            }
          }
          @media screen and (max-width: 920px) {
            gap: 2rem;
            & > div {
              .flavor {
                width: 4rem;
                height: 4rem;
              }
            }
          }
        }
      }
      @media screen and (max-width: 750px) {
        display: block;
        margin: 0 auto;
        width: 100%;
        .image {
          .ice-cream {
            max-width: 34rem;
            margin: 0 auto;
            display: block;
          }
          .blob {
            top: inherit;
            left: inherit;
            right: 10rem;
            bottom: 0rem;
            &.brown {
              top: inherit;
              left: inherit;
              bottom: 7rem;
            }
            &.cream {
              top: inherit;
              left: inherit;
              bottom: 0rem;
              right: 0rem;
            }
          }
        }
        .content {
          text-align: center;
          margin: 0 auto;
          .flavors {
            align-items: flex-start;
            justify-content: center;
          }
        }
      }
    }
    &.frozen {
      max-width: 115rem;
      .product-flex {
        align-items: center;
        justify-content: center;
        .image {
          img {
            max-width: 35rem;
            height: auto;
            border-radius: 1rem;
            margin: 0 auto;
            display: block;
          }
        }
        .content {
          p {
            max-width: 100%;
            width: 100%;
          }
          h3 {
            margin-top: 0rem;
            margin-bottom: 0.75rem;
            text-decoration: none;
          }
        }
        @media screen and (max-width: 990px) {
          display: block;
          .image {
            img {
              margin-bottom: 2rem;
            }
          }
          .content {
            p,
            h3 {
              padding-bottom: 1.5rem;
            }
            p,
            h3 {
              text-align: center;
              max-width: 70rem;
              margin: 0 auto;
            }
          }
        }
      }
    }
    &.more-desserts {
      text-align: center;
      h3 {
        font-size: 3.5rem;
      }
      .dessert-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        & > div {
          align-self: flex-start;
          justify-self: center;
          .image-container {
            img {
              max-width: 100%;
              height: auto;
              display: block;
            }
          }
          h4 {
            font-size: 3rem;
            margin-top: 1.25rem;
          }
        }
      }
      @media screen and (max-width: 800px) {
        .dessert-grid {
          & > div {
            h4 {
              font-size: 2.2rem;
            }
          }
        }
      }
      @media screen and (max-width: 550px) {
        .dessert-grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
`;

const Menu = () => {
  return (
    <StyledContainer>
      <div className="container">
        <GlobalTitle src={menuTitle} alt="Menu" className="title" id="menu" />
        <div className="product-flex">
          <div className="image">
            <motion.img
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1, transition: { duration: 0.75 } }}
              src={concrete}
              className="ice-cream"
              alt="Concrete Frozen Custard"
            />
            <img
              src={blobPink}
              className="blob"
              alt="Concrete Frozen Custard"
            />
          </div>
          <div className="content">
            <h3>Concrete</h3>
            <p>Add your favorite mix ins to create your perfect flavor.</p>
            <div className="available">available in</div>
            <div className="flavors">
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor chocolate"
                >
                  &nbsp;
                </motion.div>
                <div className="label">chocolate</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor vanilla"
                >
                  &nbsp;
                </motion.div>
                <div className="label">vanilla</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor fotd"
                >
                  &nbsp;
                </motion.div>
                <div className="label">flavor of the day</div>
              </div>
              <div>
                <a
                  href={require("../static/assets/menu-revised-2024.pdf")}
                  target="_blank"
                  rel="referrer"
                >
                  <motion.div
                    whileHover={{
                      scale: [1, 3, 1],
                      transition: { duration: 0.2 },
                    }}
                    className="flavor all-flavors"
                  >
                    &nbsp;
                  </motion.div>
                  <div className="label">all flavors &gt;</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="product-flex reversed">
          <div className="image">
            <motion.img
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1, transition: { duration: 0.75 } }}
              src={nutConcrete}
              className="ice-cream"
              alt="Concrete Frozen Custard"
            />
            <img
              src={blobBrown}
              className="blob brown"
              alt="Concrete Frozen Custard"
            />
          </div>
          <div className="content">
            <h3>Nut Concrete</h3>
            <p>
              Concretes gone nuts! Some fan favorites are Butter Pecan, Caramel
              Cashew, Peanut Cluster, and Toffee Almond just to name a few...
            </p>
            <div className="available">available in</div>
            <div className="flavors">
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor almonds"
                >
                  &nbsp;
                </motion.div>
                <div className="label">almonds</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor cashews"
                >
                  &nbsp;
                </motion.div>
                <div className="label">cashews</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor pecans"
                >
                  &nbsp;
                </motion.div>
                <div className="label">pecans</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor peanuts"
                >
                  &nbsp;
                </motion.div>
                <div className="label">peanuts</div>
              </div>
              <div>
                <a
                  href={require("../static/assets/menu-revised-2024.pdf")}
                  target="_blank"
                  rel="referrer"
                >
                  <motion.div
                    whileHover={{
                      scale: [1, 3, 1],
                      transition: { duration: 0.2 },
                    }}
                    className="flavor all-flavors"
                  >
                    &nbsp;
                  </motion.div>
                  <div className="label">all flavors &gt;</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="product-flex">
          <div className="image">
            <motion.img
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1, transition: { duration: 0.75 } }}
              src={takeHome}
              className="ice-cream"
              alt="Concrete Frozen Custard"
            />
            <img
              src={blobCream}
              className="blob cream"
              alt="Concrete Frozen Custard"
            />
          </div>
          <div className="content">
            <h3>Take Home</h3>
            <p>
              An assortment of flavors are available for take home, including
              the flavor of the day which can be packed fresh.
            </p>
            <div className="available">available in</div>
            <div className="flavors">
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor pint"
                >
                  &nbsp;
                </motion.div>
                <div className="label">Pint</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor quart"
                >
                  &nbsp;
                </motion.div>
                <div className="label">Quart</div>
              </div>
              <div>
                <motion.div
                  whileHover={{
                    scale: [1, 3, 1],
                    transition: { duration: 0.2 },
                  }}
                  className="flavor box"
                >
                  &nbsp;
                </motion.div>
                <div className="label">buy 2 or more quarts &amp; save</div>
              </div>
              <div>
                <a
                  href={require("../static/assets/menu-revised-2024.pdf")}
                  target="_blank"
                  rel="referrer"
                >
                  <motion.div
                    whileHover={{
                      scale: [1, 3, 1],
                      transition: { duration: 0.2 },
                    }}
                    className="flavor all-flavors"
                  >
                    &nbsp;
                  </motion.div>
                  <div className="label">see menu &gt;</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container frozen">
        <div className="product-flex">
          <div className="image">
            <img src={frozencustard} alt="Concrete Frozen Custard" />
          </div>
          <div className="content" id="frozen-custard">
            <h3>What is frozen custard?</h3>
            <p>
              It is a super premium ice cream very similar to homemade ice
              cream. It is made fresh regularly throughout the day. It is made
              with all natural ingredients. There are no preservatives or
              artificial ingredients like seen in many store-bought products. It
              has some egg in it, which provides a smooth and creamy texture. It
              is served at approximately 26° if which allows your taste buds to
              get a true taste.
            </p>
            <p>
              Regular ice cream is served at approximately 10° F which tends to
              "numb" the taste buds. Another big difference is the air content.
              Although all ice cream has air whipped into it to become a frozen
              confection, frozen custard has much less air. This makes it
              heavier, fresher and therefore, weighs a lot more than most other
              ice cream's. A half gallon of our frozen custard weighed against a
              half gallon of regular ice cream weighs considerably more.
            </p>
          </div>
        </div>
      </div>
      <div className="container more-desserts">
        <h3>But wait, there's more!</h3>
        <p>
          Did you know you we can whip you up the following delicious treats?
        </p>
        <div className="dessert-grid">
          <div>
            <div className="image-container">
              <img src={toppers} alt="Toppers" />
            </div>
            <div>
              <h4>Sundae</h4>
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={cones} alt="Cones" />
            </div>
            <div>
              <h4>Cones</h4>
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={floats} alt="Floats" />
            </div>
            <div>
              <h4>Floats</h4>
            </div>
          </div>
          <div>
            <div className="image-container">
              <img src={soda} alt="Soda" />
            </div>
            <div>
              <h4>Soda</h4>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Menu;
