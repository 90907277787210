import styled, { createGlobalStyle } from "styled-components"
const GlobalStyle = createGlobalStyle`
    html {
        font-size: 62.5%;
        scroll-behavior: smooth;
    }
    html, body {
        padding: 0;
        margin: 0;
        font-family: ${(props) => props.theme.fonts.main};
    }
    h1, h2, h3, h4, h5 {
        font-family: ${(props) => props.theme.fonts.main};
    }
    div, header, p, a, span {
        font-family: ${(props) => props.theme.fonts.main};
        font-size: 1.7rem;
    }
    a.button {
        background: ${(props) => props.theme.colors.primary};
        color: #fff;
        border-radius: 5rem;
        padding: 1rem 2.5rem;
        transition: all .25s ease-in-out;
        display: inline-block;
        cursor: pointer;
        &:hover {
            background: #B41121;
        }
        &.blue {
            background: #19609B;
        }
        &.red {
            background: #AB1D2D;
        }
    }
`

export const GlobalTitle = styled.img`
    padding: 5rem 0rem;
`

export default GlobalStyle
