import styled from "styled-components"
import { motion } from "framer-motion"
import { GlobalTitle } from "../styles/styles"
import titleGiftCard from "../static/assets/title-giftcards.webp"
import checkBoard from "../static/assets/checkboard.webp"
import handGiftCard from "../static/assets/hand-giftcard.webp"

const StyledContainer = styled.div`
    .container {
        max-width: 114rem;
        margin: 0 auto;
        padding: 3rem;
    }
    .title {
        max-width: 27rem;
    }
    .gift-container {
        max-width: 100%;
        position: relative;
        background: ${(props) => props.theme.colors.primary};
        .gift-card {
            position: absolute;
            max-width: 65rem;
            top: 15rem;
            left: 0rem;
            @media screen and (max-width: 1280px) {
                max-width: 50vw;
                top: 33%;
            }
        }
        .checkboard-container {
            overflow: hidden;
            img {
                display: block;
            }
        }
        .flex {
            gap: 5rem;
            display: flex;
            max-width: 114rem;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            color: #fff;
            padding: 15rem 3rem;
            & > div {
                flex: 1;
            }
        }
        @media screen and (max-width: 600px) {
            .gift-card {
                top: 10rem;
            }
            .flex {
                display: block;
                text-align: center;
                padding: 15rem 3rem 5rem 3rem;
            }
        }
    }
`

const GiftCards = () => {
    return (
        <StyledContainer>
            <div className="container">
                <GlobalTitle src={titleGiftCard} alt="Menu" className="title" id="gift-cards" />
            </div>
            <div className="gift-container">
                <motion.img initial={{ x: -100 }} whileInView={{ x: 0, transition: { duration: 1 } }} viewport={{ once: true }} src={handGiftCard} className="gift-card" alt="Nielsens Frozen Custard Gift Card" />
                <div className="checkboard-container">
                    <img src={checkBoard} alt="Checkered Board - Nielsens" />
                </div>
                <div className="flex">
                    <div>&nbsp;</div>
                    <div>
                        <h2>Give the gift of frozen custard!</h2>
                        <p>A Nielsen's Frozen Custard gift card is a perfect gift for any occasion! Nielsen's Frozen Custard gift cards are available at any of our Las Vegas locations.</p>
                    </div>
                </div>
                <div className="checkboard-container">
                    <img src={checkBoard} alt="Checkered Board - Nielsens" />
                </div>
            </div>
        </StyledContainer>
    )
}

export default GiftCards
