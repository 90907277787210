import styled from "styled-components"
import titleReviews from "../static/assets/title-reviews.webp"
import { reviews } from "../data/reviews"
import { motion } from "framer-motion"
import checkBoard from "../static/assets/checkboard.webp"

const StyledContainer = styled.div`
    margin: 7rem auto;
    .container {
        max-width: 114rem;
        margin: 0 auto;
        padding: 3rem;
        .title {
            max-width: 21rem;
            padding-bottom: 5rem;
        }
        .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5rem;
            margin-bottom: 7.5rem;
            & > .flex {
                gap: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .image {
                    img {
                        transition: all 0.25s ease-in-out;
                        width: 20rem;
                        height: 20rem;
                        display: block;
                    }
                }
                .content {
                    flex-grow: 1;
                    hr {
                        margin: 2.5rem 0rem;
                    }
                    .source {
                        font-weight: bold;
                        color: #555;
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                grid-gap: 2rem;
                & > .flex {
                    align-items: flex-start;
                    padding-bottom: 5rem;
                    .image {
                        img {
                            width: 15rem;
                            height: 15rem;
                        }
                    }
                }
            }
            @media screen and (max-width: 800px) {
                display: block;
                max-width: 60rem;
                margin: 0 auto;
                & > .flex {
                    padding-bottom: 7.5rem;
                }
            }
            @media screen and (max-width: 600px) {
                & > .flex {
                    display: block;
                    max-width: 40rem;
                    margin: 0 auto;
                    text-align: center;
                    img {
                        display: block;
                        margin: 0 auto;
                        padding-bottom: 2rem;
                    }
                }
            }
        }
    }
    .checkboard-container {
        overflow: hidden;
        img {
            display: block;
        }
    }
    .video-container {
        width: 100%;
        margin: 0 auto;
        padding: 6rem 0rem;
        background: #fff;
        iframe {
            width: 90rem;
            height: 50rem;
            display: block;
            padding: 1rem;
            margin: 0 auto;
            border: 0.1rem solid ${(props) => props.theme.colors.primary};
        }
        img {
            max-width: 50rem;
            margin: 0 auto;
            display: block;
            padding-top: 2rem;
        }
        @media screen and (max-width: 940px) {
            iframe {
                width: 60rem;
                height: 32rem;
            }
            img {
                max-width: 40rem;
            }
        }
        @media screen and (max-width: 620px) {
            iframe {
                width: 45rem;
                height: 28rem;
            }
            img {
                max-width: 30rem;
            }
        }
        @media screen and (max-width: 500px) {
            iframe {
                width: 32rem;
                height: 18rem;
            }
        }
    }
`

const Reviews = () => {
    return (
        <StyledContainer>
            <div className="container">
                <img src={titleReviews} className="title" id="reviews" alt="Reivews - Nielsens Frozen Custard" />
                <div className="grid">
                    {reviews.map((review) => {
                        return (
                            <div key={review.image} className="flex">
                                <div className="image">
                                    <img src={review.image} alt="Nielsen's testimonal and review" />
                                </div>
                                <div className="content">
                                    <div className="quote">{review.title}</div>
                                    <hr />
                                    <div className="source">{review.source}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </StyledContainer>
    )
}

export default Reviews
