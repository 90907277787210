import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import logo from "../static/assets/logo.webp";
import { routes } from "../data/routes";
import useWindowSize from "../hooks/useWindowSize";
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { FaYelp } from "react-icons/fa";
import { AiOutlineDoubleRight } from "react-icons/ai";
import useApplication from "../hooks/useApplication";

const StyledHeader = styled.div`
  width: 100%;
  position: absolute;
  height: auto;
  top: 0rem;
  left: 0rem;
  header {
    margin: 0 auto;
    max-width: 114rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 3rem;
    .mobile {
      cursor: pointer;
      color: #ccc;
      border-radius: 0.75rem;
      background: ${(props) => props.theme.colors.primary};
      padding: 0.75rem 1rem 0.25rem 1rem;
      transition: all 0.25s ease-in-out;
      span {
        position: relative;
        bottom: 0.3rem;
        padding-right: 0.25rem;
        text-transform: uppercase;
        font-size: 1.4rem;
      }
      &:hover {
        color: #fff;
      }
    }
    .logo {
      flex-grow: 1;
      img {
        max-width: 20rem;
        height: auto;
        display: block;
        @media screen and (max-width: 500px) {
          max-width: 15rem;
        }
        @media screen and (max-width: 380px) {
          max-width: 12rem;
        }
      }
    }
    .link {
      padding: 0rem 1rem;
      color: #eee;
      text-shadow: 0.1rem 0.1rem 0rem #000;
      text-decoration: none;
      text-transform: capitalize;
      transition: all 0.25s ease-in-out;
      &:hover {
        color: #fff;
      }
    }
    .social {
      padding-left: 3rem;
    }
  }
`;

const StyledBanner = styled.div`
  background: ${(props) => props.theme.colors.primary};
  z-index: 10;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0rem;
  color: #fff;
  h1 {
    font-size: 2rem;
  }
  p,
  a {
    font-size: 1.4rem;
  }

  a {
    color: #fff;
    font-weight: bold;
    font-size: 1.2rem;
  }
  svg {
    position: relative;
    top: 0.2rem;
  }
`;

const JobBanner = () => {
  const { mailString } = useApplication();
  return (
    <StyledBanner>
      <h1>If you are interested in joining our team, please apply below.</h1>
      <p>
        South Eastern, Red Rock Casino, Santa Fe Casino, Durango Resort{" "}
        <a href={mailString}>Apply Now</a>
      </p>
    </StyledBanner>
  );
};

const Header = () => {
  const window = useWindowSize();
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title data-rh="true">Nielsens Frozen Custard</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Nielsens Frozen Custard in Las Vegas, Nevada"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="keywords"
          content="frozen custard, ice cream, dessert, las vegas, nevada"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        {/* <link rel="icon" type="image/x-icon" href={favicon} /> */}
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,600;1,400&family=Satisfy&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <StyledHeader>
        <header>
          <div className="logo">
            <motion.img
              src={logo}
              initial={{ rotate: -100, x: 50, y: -100, opacity: 0 }}
              whileInView={{
                rotate: 0,
                x: 0,
                y: 0,
                opacity: 1,
                transition: { duration: 1 },
              }}
              alt="Nielsens Frozen Custard"
            />
          </div>
          {window.width && window.width > 780 ? (
            <>
              {routes.map((route) => {
                return (
                  <div key={route.title}>
                    <a className="link" href={`${route.link}`}>
                      {route.title}
                    </a>
                  </div>
                );
              })}
              <Social />
            </>
          ) : (
            <motion.div
              whileHover={{
                scale: 1.25,
                transition: { duration: 0.1, delay: 0 },
              }}
              className="mobile"
              onClick={() => setMobileMenu(true)}
            >
              <span>Menu</span> <GiHamburgerMenu />
            </motion.div>
          )}
        </header>
      </StyledHeader>
      <MobileMenu setMobileMenu={setMobileMenu} mobileMenu={mobileMenu} />
      <JobBanner />
    </>
  );
};

const StyledMenuContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0rem;
  left: 0rem;
  z-index: 5;
  overflow: hidden;
  background: ${(props) => props.theme.colors.primary};
  .close {
    position: absolute;
    cursor: pointer;
    right: 3rem;
    top: 3rem;
    width: 3rem;
    height: 3rem;
    color: #fff;
  }
  .content {
    color: #fff;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .logo {
      max-width: 15rem;
      margin: 0 auto;
      padding-bottom: 2.5rem;
      height: auto;
      display: block;
    }
    .disclaimer {
      text-align: center;
      span {
        a {
          color: #fff;
          text-decoration: none;
        }
      }
      span,
      a {
        font-size: 1.3rem;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0%;
      text-align: center;
      li {
        padding: 1.5rem;
        a {
          color: #fff;
          font-size: 2.4rem;
          text-decoration: none;
        }
      }
    }
  }
`;

type MobileMenuProps = {
  mobileMenu: boolean;
  setMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  const year = new Date().getFullYear();
  const { mobileMenu, setMobileMenu } = props;
  return (
    <AnimatePresence>
      {mobileMenu && (
        <StyledMenuContainer
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: "-110vh", transition: { duration: 0.5 } }}
        >
          <AiOutlineCloseCircle
            className="close"
            onClick={() => setMobileMenu(false)}
          />
          <div className="content">
            <div>
              <img src={logo} className="logo" alt="Nielsens Frozen Custard" />
              <ul>
                {routes.map((route) => {
                  return (
                    <motion.li key={route.title} whileHover={{ scale: 1.25 }}>
                      <a onClick={() => setMobileMenu(false)} href={route.link}>
                        {route.title}
                      </a>
                    </motion.li>
                  );
                })}
              </ul>
              <Social white />
              <p className="disclaimer">
                &copy; {year} Nielsen's Frozen Custard. <br />
                All Rights Reserved.{" "}
                <span>
                  Made by{" "}
                  <a
                    href="https://kanvas.agency"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kanvas
                  </a>
                </span>
              </p>
            </div>
          </div>
        </StyledMenuContainer>
      )}
    </AnimatePresence>
  );
};

const StyledSocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    a {
      color: #fff;
      padding-right: 0.5rem;
      svg {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }
  &.white {
    padding: 1.5rem 0rem;
    & > div {
      a {
        color: #fff;
        padding: 0rem 0.5rem;
        svg {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }
`;

type SocialProps = {
  white?: boolean;
};

export const Social: React.FC<SocialProps> = (props) => {
  const social = [
    {
      type: "facebook",
      link: "https://www.facebook.com/NielsensFrozenCustardLasVegas/",
      icon: <AiFillFacebook />,
    },
    // {
    //     type: "twitter",
    //     link: "https://twitter.com/nielsensfclv?lang=en",
    //     icon: <AiFillTwitterSquare />,
    // },
    {
      type: "yelp",
      link: "https://www.yelp.com/biz/nielsen-s-frozen-custard-las-vegas",
      icon: <FaYelp />,
    },
  ];

  const { white } = props;

  return (
    <StyledSocialContainer className={white ? "white" : ""}>
      {social.map((social) => {
        return (
          <div key={social.type}>
            <a href={social.link}>{social.icon}</a>
          </div>
        );
      })}
    </StyledSocialContainer>
  );
};

export default Header;
