import reviews1 from "../static/assets/reviews-1.jpg"
import reviews2 from "../static/assets/reviews-2.jpg"
import reviews3 from "../static/assets/reviews-3.jpg"
import reviews4 from "../static/assets/reviews-4.jpg"
import reviews5 from "../static/assets/reviews-5.jpg"
import reviews6 from "../static/assets/reviews-6.jpg"
import reviews7 from "../static/assets/reviews-7.jpg"
import reviews8 from "../static/assets/reviews-8.jpg"

type ReviewsProps = {
    image: string
    title: string
    source: string
}

export const reviews: ReviewsProps[] = [
    {
        image: reviews1,
        title: "Nielsen's Frozen Custard is one of the '100 Best Things to Eat in America'.",
        source: "Men's Journal Magazine",
    },
    {
        image: reviews8,
        title: "Frozen custard...I liked it even better than ice cream...I didn't know that custard was made from milk, rather than cream, and that it therefore contained less butterfat than ice cream. I simply preferred the texture.",
        source: "Health Magazine",
    },
    {
        image: reviews2,
        title: "Nor is ice cream quite on par with frozen custard, which is smoother and richer and considerably rarer. It's what ice cream ought to be.",
        source: "The Wall Street Journal",
    },
    {
        image: reviews7,
        title: "The best of Tampa Bay: Best Ice Cream - Nielsen's Frozen Custard (No contest here folks)",
        source: "The Tampa Bay Times",
    },
    {
        image: reviews3,
        title: "Nielsen's offers a delicacy that should not be missed.",
        source: "The Washington Post",
    },
    {
        image: reviews5,
        title: "The frozen custard stand is where God goes to get his ice cream.",
        source: "The Wall Street Journal",
    },
    {
        image: reviews4,
        title: "Made much as it was on Coney Island in the 1920s, the soft, cold, creamy custard tastes exactly like it should but nothing else. It is thick, velvety, intensely rich, smoother than ice cream...All frozen custard is ice cream, but not all ice cream is frozen custard.",
        source: "The New York Times",
    },
    {
        image: reviews6,
        title: "Few people under 50 have ever tasted real frozen custard. It's what super-premium ice cream might wish to be...it has very little air, which even the best ice creams have lots of...and is super smooth and creamy.",
        source: "Forbes Magazine",
    },
]
