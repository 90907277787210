export const routes = [
  {
    title: "menu",
    link: "#menu",
  },
  {
    title: "gift cards",
    link: "#gift-cards",
  },
  {
    title: "reviews",
    link: "#reviews",
  },
  {
    title: "about us",
    link: "#about-us",
  },
  {
    title: "loyalty",
    link: "https://nielsenscustard.myguestaccount.com/en-us/guest/",
  },
];
