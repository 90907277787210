import { DefaultTheme } from "styled-components"

// and extend them!
declare module "styled-components" {
    export interface DefaultTheme {
        fonts: {
            header: string
            main: string
            script: string
        }
        maxWidth: string
        midWidth: string
        smWidth: string
        colors: {
            primary: string
            secondary: string
            tertiary: string
            text: string
            background: string
            error: string
        }
    }
}

export const theme: DefaultTheme = {
    fonts: {
        header: "Rubik, sans-serif",
        main: "Rubik, sans-serif",
        script: "Satisfy, serif",
    },
    maxWidth: "90rem",
    midWidth: "76.8rem",
    smWidth: "64rem",
    colors: {
        primary: "#C81628",
        secondary: "#47991A",
        tertiary: "#FF39D5",
        text: "#ffffff",
        background: "#000000",
        error: "#E7494B",
    },
}
