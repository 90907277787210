import { useEffect, useState } from "react"
import styled from "styled-components"
import useApplication from "../hooks/useApplication"
const StyledForm = styled.form`
    max-width: 60rem;
    padding: 0rem 2.5rem;
    margin: 2rem auto 4rem auto;
    p {
        text-align: center;
        font-size: 1.4rem;
        a {
            font-size: 1.4rem;
            color: #000;
        }
    }
    h1 {
        text-align: center;
    }
    article,
    fieldset {
        display: block;
    }
    .flex {
        gap: 2.5rem;
        display: flex;
        margin: 2.5rem auto;
        & > div {
            flex: 1;
            label {
                display: block;
                font-size: 1.3rem;
                margin-bottom: 0.5rem;
            }
            input {
                width: 100%;
            }
        }
    }
    fieldset {
        margin: 1.5rem auto;
        & > div {
            margin: 1.5rem 0rem;
        }
    }
    input {
        padding: 1rem;
        box-sizing: border-box;
        font-family: ${(props) => props.theme.fonts.main};
    }
    input[type="submit"] {
        padding: 1rem 2rem;
        border: 0.1rem solid #666;
        background: ${(props) => props.theme.colors.primary};
        color: #fff;
        font-weight: bold;
        font-family: ${(props) => props.theme.fonts.main};
        &:disabled {
            background: none;
            border: none;
            color: green;
            padding: 0rem;
            margin: 0rem;
            font-size: 1.6rem;
        }
    }
`

const StyledButton = styled.div`
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 0.5rem;
    margin-bottom: 5rem;
    p {
        font-size: 2.1rem;
        margin-bottom: 3rem;
    }
    a {
        color: #fff;
        font-weight: bold;
        font-size: 2.4rem;
        border-radius: 1rem;
        padding: 2rem 2.5rem;
        text-decoration: none;
        white-space: nowrap;
        background: ${(props) => props.theme.colors.primary};
    }
`

enum state {
    default,
    loading,
    success,
    error,
}

const Application = () => {
    const [fullName, setFullName] = useState<string>()
    const [dob, setDob] = useState<string>()
    const [phone, setPhone] = useState<number>()
    const [email, setEmail] = useState<string>()
    const [homeAddress, setHomeAddress] = useState<string>()
    // const [emergencyName, setEmergencyName] = useState<string>()
    // const [emergencyContact, setEmergencyContact] = useState<number>()
    const [location, setLocation] = useState<string[]>([])
    const [shift, setShift] = useState<string[]>([])
    const [availability, setAvailability] = useState<string[]>([])
    const [apiState, setApiState] = useState<state>(state.default)
    const [message, setMessage] = useState<string>()
    const [refName1, setRefName1] = useState<string>()
    const [refPhone1, setRefPhone1] = useState<number>()
    const [refCompany1, setRefCompany1] = useState<string>()
    const [refName2, setRefName2] = useState<string>()
    const [refPhone2, setRefPhone2] = useState<number>()
    const [refCompany2, setRefCompany2] = useState<string>()
    const [refName3, setRefName3] = useState<string>()
    const [refPhone3, setRefPhone3] = useState<number>()
    const [refCompany3, setRefCompany3] = useState<string>()
    const [attachment, setAttachment] = useState<File>()

    const arrayHandler = (state: string[], setState: React.Dispatch<React.SetStateAction<string[]>>, value: string) => {
        if (state.some((values) => values === value)) {
            const newState = state.filter((states: string) => states !== value)
            setState(newState)
        } else {
            setState((states) => [...states, value])
        }
    }

    // TODO: clean up CSS

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setApiState(state.loading)

        // const formData = new FormData()
        // formData.append("resume", attachment!)
        // formData.append("name", fullName!)
        // formData.append("dob", dob!)
        // formData.append("location", location!)
        // formData.append("shift", shift!)
        // formData.append("email", email!)
        // formData.append("phone", phone!)
        // formData.append("homeAddress", homeAddress!)

        const payload = {
            name: fullName,
            dob,
            location,
            shift,
            email,
            phone,
            homeAddress,
            availability,
            refName1,
            refPhone1,
            refCompany1,
            refName2,
            refPhone2,
            refCompany2,
            refName3,
            refPhone3,
            refCompany3,
            // attachment: formData,
        }

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        }
        // https://nielsens-nodemailer.vercel.app/send
        fetch("https://nielsens-nodemailer.vercel.app/send", options)
            .then((response) => response.json())
            .then((data) => {
                setApiState(state.success)
                setMessage(data.msg)
            })
            .catch((error) => {
                console.error("Error:", error)
                setApiState(state.error)
                setMessage("Sorry, there was an error. Please try again later or contact the web adminstrator.")
            })
    }

    useEffect(() => {
        const script = document.createElement("script")
        script.src = "https://form.jotform.com/jsform/230575299949071"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const { mailString } = useApplication()

    return (
        // encType="multipart/form-data"
        // <StyledForm onSubmit={(e) => submitHandler(e)} id="application">
        //     <h1>Join the Nielsens team</h1>
        //     <p>
        //         Please fill out the form below. For employment inquiries, please contact us at <a href="mailto:nielsensfrozencustardlasvegas@gmail.com">nielsensfrozencustardlasvegas@gmail.com</a>
        //     </p>
        //     <div className="flex">
        //         <div>
        //             <label htmlFor="name">Full Name</label>
        //             <input type="text" id="name" name="name" placeholder="First and Last Name" required onChange={(e) => setFullName(e.target.value)} />
        //         </div>
        //         <div>
        //             <label htmlFor="dob">Date of Birth</label>
        //             <input type="date" id="dob" name="dob" required onChange={(e) => setDob(e.target.value)} />
        //         </div>
        //     </div>
        //     <div className="flex">
        //         <div>
        //             <label htmlFor="email">Email Address</label>
        //             <input type="email" id="email" name="email" placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)} />
        //         </div>
        //         <div>
        //             <label htmlFor="phone">Phone Number</label>
        //             <input type="tel" id="phone" name="phone" onChange={(e) => setPhone(parseInt(e.target.value))} />
        //         </div>
        //     </div>
        //     <div className="flex">
        //         <div>
        //             <label htmlFor="homeAddress">Home Address</label>
        //             <input type="text" id="homeAddress" name="homeAddress" placeholder="Home Address" required onChange={(e) => setHomeAddress(e.target.value)} />
        //         </div>
        //     </div>
        //     {/* <div className="flex">
        //         <div>
        //             <label htmlFor="emergencyName">Emergency Contact Name</label>
        //             <input type="text" id="emergencyName" name="emergencyName" placeholder="Email Address" required onChange={(e) => setEmergencyName(e.target.value)} />
        //         </div>
        //         <div>
        //             <label htmlFor="emergencyPhone">Emergency Phone Number</label>
        //             <input type="number" id="emergencyPhone" name="emergencyPhone" required onChange={(e) => setEmergencyContact(parseInt(e.target.value))} />
        //         </div>
        //     </div> */}
        //     <fieldset>
        //         <legend>Location</legend>
        //         <p>Please choose a location you are applying for? (Check all that apply)</p>
        //         <div>
        //             <input type="checkbox" id="henderson" name="henderson" onChange={(e) => arrayHandler(location, setLocation, e.target.name)} />
        //             <label htmlFor="henderson">Henderson (Eastern/215)</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="summerlin" name="summerlin" onChange={(e) => arrayHandler(location, setLocation, e.target.name)} />
        //             <label htmlFor="summerlin">Summerlin Location (Red Rock Casino)</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="santafe" name="santafe" onChange={(e) => arrayHandler(location, setLocation, e.target.name)} />
        //             <label htmlFor="santafe">North Location (Santa Fe Casino)</label>
        //         </div>
        //     </fieldset>
        //     <fieldset>
        //         <legend>Shift</legend>
        //         <p>Which shift do you prefer? (Check all that apply)</p>
        //         <div>
        //             <input type="checkbox" id="day" name="day" onChange={(e) => arrayHandler(shift, setShift, e.target.name)} />
        //             <label htmlFor="day">Day shift</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="night" name="night" onChange={(e) => arrayHandler(shift, setShift, e.target.name)} />
        //             <label htmlFor="night">Night shift</label>
        //         </div>
        //     </fieldset>
        //     <fieldset>
        //         <legend>Availability</legend>
        //         <p>What day(s) are you available? (Check all that apply)</p>
        //         <div>
        //             <input type="checkbox" id="monday" name="monday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="monday">Monday</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="tuesday" name="tuesday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="tuesday">Tuesday</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="wednesday" name="wednesday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="wednesday">Wednesday</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="thursday" name="thursday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="thursday">Thursday</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="friday" name="friday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="friday">Friday</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="saturday" name="saturday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="saturday">Saturday</label>
        //         </div>
        //         <div>
        //             <input type="checkbox" id="sunday" name="sunday" onChange={(e) => arrayHandler(availability, setAvailability, e.target.name)} />
        //             <label htmlFor="sunday">Sunday</label>
        //         </div>
        //     </fieldset>
        //     <fieldset>
        //         <legend>Reference 1</legend>
        //         <p>Please write a reference we can contact to ask about your previous experience.</p>
        //         <div className="flex">
        //             <div>
        //                 <label htmlFor="refName1">1st Reference Name</label>
        //                 <input type="text" id="refName1" name="refName1" placeholder="1st Reference Name" onChange={(e) => setRefName1(e.target.value)} />
        //             </div>
        //             <div>
        //                 <label htmlFor="refCompany1">1st Reference Company</label>
        //                 <input type="text" id="refCompany1" name="refCompany1" placeholder="1st Reference Company" onChange={(e) => setRefCompany1(e.target.value)} />
        //             </div>
        //         </div>
        //         <div className="flex">
        //             <div>
        //                 <label htmlFor="refPhone1">1st Reference Phone</label>
        //                 <input type="tel" id="refPhone1" name="refPhone1" placeholder="1st Reference Phone" onChange={(e) => setRefPhone1(parseInt(e.target.value))} />
        //             </div>
        //         </div>
        //     </fieldset>
        //     <fieldset>
        //         <legend>Reference 2</legend>
        //         <div className="flex">
        //             <div>
        //                 <label htmlFor="refName2">2nd Reference Name</label>
        //                 <input type="text" id="refName2" name="refName2" placeholder="2nd Reference Name" onChange={(e) => setRefName2(e.target.value)} />
        //             </div>
        //             <div>
        //                 <label htmlFor="refCompany2">2nd Reference Company</label>
        //                 <input type="text" id="refCompany2" name="refCompany2" placeholder="2nd Reference Company" onChange={(e) => setRefCompany2(e.target.value)} />
        //             </div>
        //         </div>
        //         <div className="flex">
        //             <div>
        //                 <label htmlFor="refPhone2">2nd Reference Phone</label>
        //                 <input type="tel" id="refPhone2" name="refPhone2" placeholder="2nd Reference Phone" onChange={(e) => setRefPhone2(parseInt(e.target.value))} />
        //             </div>
        //         </div>
        //     </fieldset>
        //     <fieldset>
        //         <legend>Reference 3</legend>
        //         <div className="flex">
        //             <div>
        //                 <label htmlFor="refName3">3rd Reference Name</label>
        //                 <input type="text" id="refName3" name="refName3" placeholder="3rd Reference Name" onChange={(e) => setRefName3(e.target.value)} />
        //             </div>
        //             <div>
        //                 <label htmlFor="refCompany3">3rd Reference Company</label>
        //                 <input type="text" id="refCompany3" name="refCompany3" placeholder="3rd Reference Company" onChange={(e) => setRefCompany3(e.target.value)} />
        //             </div>
        //         </div>
        //         <div className="flex">
        //             <div>
        //                 <label htmlFor="refPhone3">3rd Reference Phone</label>
        //                 <input type="tel" id="refPhone3" name="refPhone3" placeholder="3rd Reference Phone" onChange={(e) => setRefPhone3(parseInt(e.target.value))} />
        //             </div>
        //         </div>
        //     </fieldset>
        //     <fieldset>
        //         <legend>Resume</legend>
        //         <p>
        //             Please email us a copy of your resume with your name as the subject title to <a href="mailto:nielsensfrozencustardlasvegas@gmail.com">nielsensfrozencustardlasvegas@gmail.com</a>
        //         </p>
        //         <input type="checkbox" required /> I have emailed a copy of my resume to the address above.
        //         {/* <input type="file" name="uploadedResume" accept=".doc,.docx,.pdf" required onChange={(e) => setAttachment(e.target.files![0])} /> */}
        //     </fieldset>
        //     <input type="submit" value={apiState === state.success ? "Done" : "Submit Application"} disabled={apiState === state.success || apiState === state.loading ? true : false} />
        //     {message && <p>{message}</p>}
        // </StyledForm>
        <StyledButton>
            <p>Want to be part of the Nielsens team?</p>
            <a href={mailString}>Apply Now</a>
            {/* <a href="javascript:void( window.open( 'https://form.jotform.com/230576093298162', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) ) "> Apply Now</a> */}
        </StyledButton>
    )
}

export default Application
