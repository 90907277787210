const useApplication = () => {
  const subject = "Application to Nielsens Frozen Custard";
  const emailAddress = "nielsensfrozencustardlasvegas@gmail.com";
  const mainBody = `Please fill the application below.
        \n
        First and Last Name:
        \n
        Email Address:
        \n
        Date of Birth:
        \n
        Home Address:
        \n
        Phone Number:
        \n
        What location are you applying to? (Specify Eastern/215, Red Rock, Durango Casino, Santa Fe Casino, or All):
        \n
        What shifts are you available to work? (Day shift, night shift, or both/no preference):
        \n
        What days are you available? (Mon, Tues, Wed, Thurs, Fri, Sat, Sun):
        \n
        Please provide up to three references we can contact and ask about you. Provide their name, the company/relation, and phone number:
        \n
        Please attach your resume to this email. Thank you!
    `;
  const emailBody = encodeURIComponent(mainBody);

  const mailString = `mailto:${emailAddress}?Subject=${subject}&body=${emailBody}`;

  return {
    mailString,
  };
};

export default useApplication;
