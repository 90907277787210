import styled from "styled-components"
import titleAbout from "../static/assets/title-about.webp"
import magazine from "../static/assets/magazine.webp"
import checkboard from "../static/assets/checkboard.webp"
import logo from "../static/assets/logo.webp"
import { motion } from "framer-motion"
import owners from "../static/assets/howard-amanda.webp"
import checkBoard from "../static/assets/checkboard.webp"
import delicious from "../static/assets/delicious.webp"

const StyledContainer = styled.div`
    margin: 10rem auto 5rem auto;
    .container {
        max-width: 114rem;
        margin: 0 auto;
        padding: 3rem;
        .title {
            max-width: 23rem;
            padding-bottom: 5rem;
        }
        .about-us-container {
            .flex {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                & > div {
                    &.magazine-container {
                        flex: 0.8;
                        position: relative;
                        left: 2rem;
                        z-index: 1;
                        img {
                            max-width: 100%;
                            height: auto;
                            display: block;
                            box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.35);
                        }
                        h2,
                        span {
                            font-size: 2.4rem;
                            color: #515151;
                        }
                        span {
                            font-weight: normal;
                        }
                    }
                    &.content-container {
                        flex: 1;
                        background: #eee;
                        width: 100%;
                        overflow: hidden;
                        position: relative;
                        top: 5rem;
                        right: 1rem;
                        z-index: 0;
                        .checkboard {
                            display: block;
                        }
                        .content {
                            padding: 6rem 3rem 6rem 6rem;
                            h2 {
                                color: #000;
                            }
                            p {
                                color: #515151;
                            }
                            .logo {
                                max-width: 25rem;
                                height: auto;
                                display: block;
                                padding: 3rem 0rem;
                            }
                        }
                    }
                }
                @media screen and (max-width: 720px) {
                    display: block;
                    & > div {
                        &.magazine-container {
                            position: inherit;
                            img {
                                max-width: 30rem;
                                margin: 0 auto;
                                display: block;
                                padding: 1rem;
                                box-shadow: none;
                            }
                            h2 {
                                text-align: center;
                                padding: 1rem 0rem;
                            }
                        }
                        &.content-container {
                            position: inherit;
                            img {
                                margin: 0 auto;
                                display: block;
                            }
                            .content {
                                padding: 3rem;
                                .logo {
                                    max-width: 20rem;
                                    height: auto;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .wrapper {
        background: #111;
        width: 100%;
        border-top: 0.1rem solid #ccc;
        border-bottom: 0.1rem solid #ccc;
        margin-top: 7rem;
        padding: 3rem 0rem 5rem 0rem;
        .owners-container {
            max-width: 120rem;
            margin: 0 auto;
            display: flex;
            gap: 3rem;
            align-items: flex-start;
            justify-content: center;
            padding: 0rem 3rem;
            & > div {
                flex: 1;
                img {
                    width: 100%;
                    display: block;
                    margin: 2.5rem auto 0rem auto;
                    border-radius: 1rem;
                }
                h2 {
                    color: #fff;
                }
                p {
                    color: #eee;
                }
            }
        }
    }
    @media screen and (max-width: 990px) {
        margin: 10rem auto;
        .wrapper {
            .owners-container {
                display: block;
            }
        }
    }
    .checkboard-container {
        overflow: hidden;
        img {
            display: block;
        }
    }
    .video-container {
        width: 100%;
        margin: 0 auto;
        padding: 6rem 0rem;
        background: #fff;
        iframe {
            width: 90rem;
            height: 50rem;
            display: block;
            padding: 1rem;
            margin: 0 auto;
            border: 0.1rem solid ${(props) => props.theme.colors.primary};
        }
        img {
            max-width: 50rem;
            margin: 0 auto;
            display: block;
            padding-top: 2rem;
        }
        @media screen and (max-width: 940px) {
            iframe {
                width: 60rem;
                height: 32rem;
            }
            img {
                max-width: 40rem;
            }
        }
        @media screen and (max-width: 620px) {
            iframe {
                width: 45rem;
                height: 28rem;
            }
            img {
                max-width: 30rem;
            }
        }
        @media screen and (max-width: 500px) {
            iframe {
                width: 32rem;
                height: 18rem;
            }
        }
    }
`

const About = () => {
    return (
        <StyledContainer>
            <div className="container">
                <img src={titleAbout} className="title" id="about-us" alt="Reivews - Nielsens Frozen Custard" />
                <div className="about-us-container">
                    <div className="flex">
                        <motion.div initial={{ x: -50, opacity: 0 }} whileInView={{ x: 0, opacity: 1, transition: { duration: 0.75 } }} className="magazine-container">
                            <img src={magazine} alt="Vegas Life Magazine featuring Nielsens Frozen Custard" />
                            <h2>
                                <span>Featured in</span> Vegas Life Magazine
                            </h2>
                        </motion.div>
                        <div className="content-container">
                            <img src={checkboard} className="checkboard" alt="Checkboard - Nielsens" />
                            <div className="content">
                                <p>Nielsen's Frozen Custard is proud to bring back the goodness and taste of a bygone era. Our recipe, the machine and our desire for quality all result in the rebirth of a smooth real dairy taste that people love. </p>
                                <p>Steve Nielsen started mixing, freezing, and scooping his frozen custard in Salt Lake City, Utah in 1981. Nielsen's Frozen Custard was quickly recognized as a quality product. Just a few months later after he opened, an annual "Critics Choice Award" named his frozen custard "The smoothest stuff available." </p>
                                <p>It is not just one ingredient or process that makes Nielsens frozen custard more delicious. It's a combination of the exacting recipe with real dairy cream, rich eggs, and other natural ingredients. It's our freshness. Our frozen custard is made fresh every few hours right in our store. It's the quality of the flavoring's, breakfast grade cocoas, fresh roasted nuts, real berry purées, and natural vanilla. </p>
                                <p>Our machine is a specially designed, one-of-a-kind freezing machine manufactured exclusively for Nielsens. Our machine is designed to freeze our custard recipe without pumping in extra air during the process. This allows our custard to maintain its richness, and gives it a smooth, creamy texture that is unrivaled.</p>
                                <img src={logo} className="logo" alt="Nielsen's Frozen Custard" />
                            </div>
                            <img src={checkboard} className="checkboard" alt="Checkboard - Nielsens" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="owners-container">
                    <div>
                        <img src={owners} alt="Howard and Amanda" className="owners" />
                    </div>
                    <div>
                        <h2>Meet the Owners</h2>
                        <p>
                            Nielsen's Frozen Custard In Las Vegas is owned by husband and wife team Howard and Amanda Zayon. The wonderful thing about Nielsen's is we are a family owned and operated company. We love coming to work and seeing our team members and customers. It makes such a huge difference being present and knowing our customers. When you walk through our doors we want you to feel like you stepped back in time. You know back when owners knew their customers names, knew the family
                            and what they ordered before they got to the register.
                        </p>
                        <p>
                            We love our Custardmers, we love our employees, we are one big happy family. One of the best parts of owning Nielsen's is having our children work with us. Our eldest son Brandon (25 years old), has worked with us for years, our daughter Tatiana (20 years old), currently works with us as a manager and attends college as well. Our little guy Zachary who is nine can't wait until he can work with us, but for right now he is happy being our greeter and taste tester. Come
                            on in and say hi. We'd love to meet you!
                        </p>
                    </div>
                </div>
            </div>
            <div className="checkboard-container">
                <img src={checkBoard} alt="Checkered Board - Nielsens" />
            </div>
            <div className="video-container">
                <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, scale: [1, 1.025, 1], transition: { delay: 1 } }} viewport={{ once: true }}>
                    <iframe src="https://www.youtube.com/embed/7uiPmBvSzYs?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </motion.div>
                <img src={delicious} alt="Simply Delicious" />
            </div>
        </StyledContainer>
    )
}

export default About
