import styled from "styled-components"
import checkboard from "../static/assets/checkboard.webp"
import sns1 from "../static/assets/sns1.webp"
import sns2 from "../static/assets/sns2.webp"
import sns3 from "../static/assets/sns3.webp"
import sns4 from "../static/assets/sns4.webp"

const StyledContainer = styled.div`
    width: 100%;
    height: auto;
    overflow: hidden;
    .checkboard {
        img {
            display: block;
        }
    }
    .sns-container {
        max-width: 115rem;
        padding: 6rem 3rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        & > div {
            flex: 1;
            &:nth-child(1) {
                display: grid;
                grid-gap: 1.25rem;
                grid-template-columns: repeat(2, 1fr);
                & > div {
                    border: 0.1rem solid #ccc;
                    border-radius: 1rem;
                    overflow: hidden;
                    img {
                        display: block;
                        margin: 0 auto;
                        width: 100%;
                        height: auto;
                    }
                }
            }
            h1 {
                strong {
                    font-family: ${(props) => props.theme.fonts.script};
                    position: relative;
                    bottom: 0.5rem;
                    font-size: 4rem;
                    white-space: nowrap;
                }
            }
            h2 {
                font-family: ${(props) => props.theme.fonts.script};
                line-height: 1.6rem;
                color: ${(props) => props.theme.colors.primary};
            }
            h3 {
                color: ${(props) => props.theme.colors.primary};
                font-weight: normal;
            }
            .button-container {
                display: flex;
                gap: 1.5rem;
                & > div {
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
        @media screen and (max-width: 700px) {
            display: block;
        }
    }
`

const Custard = () => {
    return (
        <StyledContainer>
            <div className="checkboard">
                <img src={checkboard} alt="Checkboard" />
            </div>
            <div className="sns-container">
                <div className="grid">
                    <div>
                        <img src={sns1} alt="Soda" />
                    </div>
                    <div>
                        <img src={sns2} alt="Soda" />
                    </div>
                    <div>
                        <img src={sns3} alt="Soda" />
                    </div>
                    <div>
                        <img src={sns4} alt="Soda" />
                    </div>
                </div>
                <div>
                    <h2>See our latest at</h2>
                    <h1>
                        Nielsens Frozen Custard <strong>Las Vegas</strong>
                    </h1>
                    <h3>#NielsensFrozenCustardLV</h3>
                    <p>Follow us on facebook and Instagram to see the news, promotions, and the flavor of the day!</p>
                    <div className="button-container">
                        <div>
                            <a className="button red" target="_blank" rel="noreferrer" href="https://www.instagram.com/nielsensfrozencustardlv/">
                                Instagram
                            </a>
                        </div>
                        <div>
                            <a className="button blue" target="_blank" rel="noreferrer" href="https://www.facebook.com/NielsensFrozenCustardLasVegas/">
                                Facebook
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkboard">
                <img src={checkboard} alt="Checkboard" />
            </div>
        </StyledContainer>
    )
}

export default Custard
